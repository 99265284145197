import React, { useState } from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import {
  faMinusCircle,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

import GoogleStreetMap from "../../../maps/GoogleMaps/Streetview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleAutoComplete from "./GoogleAutoComplete";

/** Custom Hooks */
import useAppSelector from "../../../../hooks/useAppSelector";

const Properties = ({ properties, handleTypeChange, setProperties, addProperty }) => {
  const { propertyTypes, propertySubTypes } = useAppSelector(state => state.appData);

  const handlePropertyTypeSelect = (value, index) => {
    if (!value || !propertySubTypes[value]?.length) {
      handlePropertyDataChange("propertySubTypeId", null, index)
    }
    handlePropertyDataChange("propertyTypeId", value, index)
  }

  const handleDestinationAddressSelect = (location, index) => {
    if (location) {
      const _properties = [...properties];

      _properties[index] = {
        ..._properties[index],
        ...location,
      };

      setProperties(_properties);
    }
  };

  const handlePropertyDataChange = (type, value, index) => {
    let _properties = [...properties]
    _properties[index][type] = value
    setProperties(_properties)
  }

  const removeProperty = (index) => {
    let _properties = [...properties]
    _properties.splice(index + 1, 1)

    setProperties(_properties)
  }

  const handleAutoCompleteChange = (index, e) => {
    let _properties = [...properties]
    _properties[index]['address'] = e.target.value
    setProperties(_properties)
  }

  const addNewProperty = () => {
    addProperty()
  }

  return (
    <div>
      <Form className="appraisal-wrapper card">
        <div className="scrollable">
          <div
            name="properties">
            <div>
              {properties && properties.length > 0 ? (
                properties.map((property, index) => (
                  <div key={index} className="property-item-container">
                    <Row>
                      <Col md="12" lg="12">
                        <Card className="mb-0 b-radius-0 handle-card">
                          <Card.Header className="pb-1 job-card-header">
                            <Card.Title tag="h5" className="mb-0 tk-font">
                              {`Property ${index + 1}`}
                            </Card.Title>
                          </Card.Header>
                          <div className="property-item-address">
                            <Card.Body className="py-0 job-card-body">
                              <div>
                                <h5 className="my-2">Address *</h5>
                                <div className="google-auto-complete__wrapper">
                                  <GoogleAutoComplete
                                    onLocationSelect={(location) => handleDestinationAddressSelect(location, index)}
                                    name="lane"
                                    value={property.address}
                                    unit={property.unit}
                                    onChange={(e) => handleAutoCompleteChange(index, e)}
                                    properties={properties}
                                  />
                                </div>
                              </div>
                            </Card.Body>
                            {property.googlePlaceId !== "" &&
                              <><GoogleStreetMap
                                latPos={property?.lat}
                                lngPos={property?.lng}
                              >
                                <Form.Control className="mt-2" type="text" value={property.unit} placeholder="Enter Unit" onChange={(e) => handlePropertyDataChange("unit", e.target.value, index)} /></GoogleStreetMap>
                              </>
                            }
                          </div>

                          <div className="property-item-details">
                            <Card.Header className="pb-0 job-card-header">
                              <Card.Title tag="h5" className="mb-0 tk-font">
                                Property details
                              </Card.Title>
                            </Card.Header>
                            <Card.Body className="py-1 pt-pad job-card-body">
                              <div>
                                <div className="mb-3">
                                  <h5 className="my-2">Parcel Id</h5>
                                  <Form.Control type="text" value={property.parcelId} onChange={(e) => handlePropertyDataChange("parcelId", e.target.value, index)} />
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">File Number</h5>
                                  <Form.Control type="text" value={property.fileNumber} onChange={(e) => handlePropertyDataChange("fileNumber", e.target.value, index)} />
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Client Reference</h5>
                                  <Form.Control type="text" value={property.clientReference || ""} onChange={(e) => handlePropertyDataChange("clientReference", e.target.value, index)} />
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Property Type *</h5>
                                  <div className="d-flex">
                                    <Form.Check
                                      type="radio"
                                      name={`type-${index}`}
                                      label="Commercial"
                                      value="1"
                                      id={`commercial-radio-${index}`}
                                      onChange={(e) => handleTypeChange("1", index)}
                                      checked={property.type === "1"}
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <Form.Check
                                      type="radio"
                                      name={`type-${index}`}
                                      label="Residential"
                                      id={`residential-radio-${index}`}
                                      onChange={(e) => handleTypeChange("2", index)}
                                      checked={property.type === "2"}
                                      style={{ marginLeft: "5%", cursor: 'pointer' }}
                                    />
                                    <Form.Check
                                      type="radio"
                                      name={`type-${index}`}
                                      label="Entity"
                                      id={`entity-radio-${index}`}
                                      onChange={(e) => handleTypeChange("3", index)}
                                      checked={property.type === "3"}
                                      style={{ marginLeft: "5%", cursor: 'pointer' }}
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Form.Select aria-label="Select Property Type" value={property.propertyTypeId || ""} onChange={(e) => handlePropertyTypeSelect(e.target.value, index)}>
                                    <option value="" />
                                    {
                                      propertyTypes.map((propertyType) => {
                                        return property.type === String(propertyType.type) && <option key={propertyType.id} value={propertyType.id}>{propertyType.name}</option>
                                      })
                                    }
                                  </Form.Select>
                                </div>
                                {!!propertySubTypes[property.propertyTypeId]?.length && (
                                  <div className="mb-3">
                                    <h5 className="my-2">Property Sub Type</h5>
                                    <Form.Select aria-label="Select Property Sub Type" value={property.propertySubTypeId || ""} onChange={(e) => handlePropertyDataChange("propertySubTypeId", e.target.value, index)}>
                                      <option value="" />
                                      {propertySubTypes[property.propertyTypeId].map((propertySubType) => (
                                        <option key={propertySubType.id} value={propertySubType.id}>{propertySubType.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                )}
                                <div className="mb-3">
                                  <h5 className="my-2">Property Notes</h5>
                                  <Form.Group className="mb-1" controlId="jobPurposeNotes">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      name="propertyNotes"
                                      value={property.propertyNotes.length > 0 ? property.propertyNotes[0]["note"] : ""}
                                      onChange={(e) => handlePropertyDataChange("propertyNotes", [{ id: property.propertyNotes[0].id, "note": e.target.value }], index)}
                                    />
                                  </Form.Group>
                                </div>
                                <Row className="mx-0 mt-4">
                                  {properties.length !== 1 && (
                                    <Button
                                      variant="secondary"
                                      className="mb-1 me-1"
                                      onClick={() => removeProperty(index)} // remove a friend from the list
                                    >
                                      <FontAwesomeIcon icon={faMinusCircle} />
                                    </Button>
                                  )}

                                  {properties.length === index + 1 && (
                                    <Button
                                      variant="primary"
                                      className="mb-1 me-1"
                                      onClick={() => addNewProperty()}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        className="me-1"
                                      />
                                      Add Additional Property
                                    </Button>
                                  )}
                                </Row>
                              </div>
                            </Card.Body>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <Row className="mx-0 mt-4">
                  <Button
                    variant="secondary"
                    className="mb-1 me-1"
                    onClick={() => addNewProperty()}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="me-1"
                    />
                    Add Additional Property
                  </Button>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div >
  );
};

export default Properties;
