import axios from "../utils/axios";

export function getCustomers() {
  return axios.get("/Customer/GetCustomers");
}

/**
 * @param {{
 *  customerName?: string;
 *  customerAddress?: string;
 *  firstName?: string;
 *  lastName?: string;
 *  emails?: Array<string>;
 *  phoneNumbers?: Array<string>;
 * }} query
 */
export function filterCustomers(query = {}) {
  return axios.post("/Customer/FilterCustomers", query);
}

export function updateCustomer(customerInfo) {
  return axios.post("/Customer/UpdateCustomer", customerInfo);
}

export function getCustomerById(id) {
  return axios.get(`/Customer/GetCustomerById?id=${id}`);
}

export function getCustomerContacts(id) {
  return axios.get(`/Customer/GetCustomerContacts?customerId=${id}`);
}

export function deleteCustomerContact(customerContact) {
  return axios.post("/Customer/DeleteCustomerContact", customerContact);
}

export function getCustomerRules(customerId) {
  const params = new URLSearchParams({ customerId });
  return axios.get(`/Customer/GetCustomerRules?${params}`);
}
