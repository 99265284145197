import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Button,
  Accordion
} from "react-bootstrap";

import { MultiSelect } from "react-multi-select-component";
import { Helmet } from "react-helmet-async";
// import { Formik, Form, Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import useAppSelector from "../../../../hooks/useAppSelector";
import { getAppraisalContactObj } from "../../../../utils";

const AppraisalDetails = ({ data, setData, appraisalProps, updateAppraisalProps, jobNameUpdatedFlag, ticketId }) => {
  const [filesList, setFilesList] = useState([[], []]);
  const { jobPurposes, assignmentTypes, valueOptions, signingAppraisers } = useAppSelector(state => state.appData)

  const onFilesChange = (e, index) => {
    const cloneFilesList = [...filesList];
    cloneFilesList[index].push(e?.target?.files?.[0]?.name);
    setFilesList(cloneFilesList);
  };

  const handleNameUpdate = (value) => {
    jobNameUpdatedFlag(true)
    updateAppraisalProps("name", value)
  }

  const handleValueTypesChange = (values, index) => {
    let _values = []
    values.forEach((value) => {
      _values.push(value.value)
    })

    let _data = [...data]
    _data[index]["valueTypeIds"] = _values
    setData(_data)
  }

  const handlePropertyDataChange = (type, value, index) => {
    let _data = [...data]
    _data[index][type] = value
    setData(_data)
  }

  //Handling Change Events of First Name and LastName
  const handleContactDetailsChange = (key, value, parentIndex, index) => {
    let _data = JSON.parse(JSON.stringify(data))
    _data[parentIndex]["inspectionContacts"][index][key] = value
    setData(_data)
  }

  const addNewCustomerContact = (index) => {
    let _data = JSON.parse(JSON.stringify(data))
    _data[index]["inspectionContacts"].push(getAppraisalContactObj())
    setData(_data)
  }

  const removeCustomerContact = (index, contactIndex) => {
    let _data = JSON.parse(JSON.stringify(data))
    _data[index]["inspectionContacts"].splice(contactIndex + 1, 1)
    setData(_data)
  }

  const feeWrapper = (name, value, index) => {
    handlePropertyDataChange(name, value === "" ? value : Math.abs(value), index)
  }

  const getJobName = (property) => {
    let name = ""//property.address

    if (property?.houseNumber)
      name = name + `${property.houseNumber}`

    if (property?.street)
      name = name + ` ${property.street}`

    if (property?.unit)
      name = name + ` ${property.unit}`
    return name
  }

  return (
    <React.Fragment>
      <Helmet title="Appraisal Details" />
      <Container fluid className="p-0">
        <h1 className="h4 title">Appraisal Details</h1>

        <Row>
          <Col md="12" lg="12">
            <Card className="appraisal-wrapper">
              <div className=" mb-0 scrollable bl-radius">
                <Accordion
                  defaultActiveKey={`${ticketId}`}
                  flush
                  className="appraisal-accordion"
                >
                  <Card.Header className="pb-1 job-card-header">
                    <h5>Job Name *</h5>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="job-name"
                        value={appraisalProps.name}
                        onChange={(e) => handleNameUpdate(e.target.value)}
                      />
                    </Form.Group>
                  </Card.Header>

                  <Card.Header className="pb-1 job-card-header">
                    <Card.Title tag="h5" className="mb-0  tk-font ">
                      Requested Due Date
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="py-1 job-card-body">
                    <div className="jobs-input-block">
                      <Row>
                        <Col xs={12} md={12}>
                          <Form.Control
                            name="requestedDueDate"
                            type="date"
                            value={appraisalProps.requestedDueDate || ""}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => updateAppraisalProps(e.target.name, e.target.value)}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>

                  <Card.Body className="py-1 job-card-body">
                    {data?.length > 0 &&
                      data?.map((property, index) => {
                        let _selectedValueTypes = []
                        property.valueTypeIds.forEach((id) => {
                          let _values = valueOptions.filter((obj) => obj.value === id)
                          _selectedValueTypes.push({ ..._values[0] })
                        })

                        let internalDate = property.internalDueDate?.split("T")[0] ?? '';
                        let inspectionType = property.inspectionType;

                        return (
                          <Accordion.Item
                            eventKey={`${property.ticketId}`}
                            key={`property${property.ticketId}`}
                          >
                            <Accordion.Header>{`Job Info for ${property.address === "" ? ("Property " + (index + 1)) : getJobName(property)}`}</Accordion.Header>
                            <Accordion.Body>
                              <Card.Body className="py-2 p-0">
                                <div>
                                  {property.inspectionContacts && property.inspectionContacts.length > 0 && (
                                    property.inspectionContacts.map((contact, pIndex) => (
                                      <div key={pIndex} className="appraisal-details-contact-details-container">
                                        <h5 className="my-2">Contact Details {pIndex + 1}</h5>
                                        <Row className="my-1">
                                          <Col xs={12} sm={6}>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                type="text"
                                                id={`firstName.${pIndex}`}
                                                name={`firstName.${pIndex}`}
                                                placeholder="First Name"
                                                value={contact.firstName || ""}
                                                onChange={(e) => handleContactDetailsChange("firstName", e.target.value, index, pIndex)}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xs={12} sm={6}>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                type="text"
                                                id={`lastName.${pIndex}`}
                                                name={`lastName.${pIndex}`}
                                                placeholder="Last Name"
                                                value={contact.lastName || ""}
                                                onChange={(e) => handleContactDetailsChange("lastName", e.target.value, index, pIndex)}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xs={12} sm={6}>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                type="text"
                                                id={`phone.${pIndex}`}
                                                name={`phone.${pIndex}`}
                                                placeholder="Phone"
                                                value={contact.phone || ""}
                                                onChange={(e) => handleContactDetailsChange("phone", e.target.value, index, pIndex)}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xs={12} sm={6}>
                                            <Form.Group className="mb-2">
                                              <Form.Control
                                                type="text"
                                                id={`email.${pIndex}`}
                                                name={`email.${pIndex}`}
                                                placeholder="Email"
                                                value={contact.email || ""}
                                                onChange={(e) => handleContactDetailsChange("email", e.target.value, index, pIndex)}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xl={12} sm={12}>
                                            <Form.Group className="mb-1">
                                              <Form.Control
                                                type="text"
                                                rows="3"
                                                placeholder="Note"
                                                onChange={(e) => handleContactDetailsChange("note", e.target.value, index, pIndex)}
                                                value={contact.note || ""}
                                              />
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="mx-0">
                                          {property.inspectionContacts.length === pIndex + 1 ? (
                                            <Button
                                              variant="primary"
                                              className="mb-2 me-1"
                                              onClick={() => addNewCustomerContact(index)}
                                            >
                                              <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="me-1"
                                              />
                                              Add Additional Appraisal Contacts
                                            </Button>
                                          ) : <Button
                                            variant="secondary"
                                            className="mb-2 me-1"
                                            onClick={() => removeCustomerContact(index, pIndex)}
                                          >
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                          </Button>}
                                        </Row>
                                      </div>
                                    ))
                                  )}
                                </div>

                                <div className="mb-3">
                                  <h5>Internal Due Date</h5>
                                  <Form.Control
                                    name="internalDueDate"
                                    type="date"
                                    value={internalDate}
                                    min={new Date().toISOString().split('T')[0]}
                                    onChange={(e) => handlePropertyDataChange("internalDueDate", e.target.value, index)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Signing Appraiser</h5>
                                  <Form.Select aria-label="Select signing appraiser" value={property.signingAppraiserId} onChange={(e) => handlePropertyDataChange("signingAppraiserId", e.target.value, index)}>
                                    <option value="" />
                                    {signingAppraisers.map((appraiser) => <option key={appraiser.id} value={appraiser.id}>{appraiser.firstName} {appraiser.lastName}</option>)}
                                  </Form.Select>
                                </div>

                                <div className="mb-3">
                                  <h5 className="my-2">Purpose</h5>
                                  <Form.Select aria-label="Select Purpose" value={property.jobPurposeId} onChange={(e) => handlePropertyDataChange("jobPurposeId", e.target.value, index)}>
                                    <option value="" />
                                    {jobPurposes.map((purpose) => <option key={purpose.id} value={purpose.id}>{purpose.name}</option>)}
                                  </Form.Select>
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Purpose Note</h5>
                                  <Form.Group className="mb-1" controlId="jobPurposeNotes">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      name="jobPurposeNotes"
                                      onChange={(e) => handlePropertyDataChange("purposeNote", e.target.value, index)}
                                      value={property.purposeNote}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Special Requirements</h5>
                                  <Form.Group className="mb-1" controlId="specialRequirements">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      name="specialRequirements"
                                      onChange={(e) => handlePropertyDataChange("specialRequirements", e.target.value, index)}
                                      value={property.specialRequirements}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="mb-3">
                                  <h5 className="my-2">Assignment Type</h5>
                                  <Form.Select aria-label="Select Assignment Type" value={property.jobAssignmentTypeId} onChange={(e) => handlePropertyDataChange("jobAssignmentTypeId", e.target.value, index)}>
                                    <option value="" />
                                    {assignmentTypes.map((assignment) => String(assignment.type) === String(property.type) && <option key={assignment.id} value={assignment.id}>{assignment.name}</option>)}
                                  </Form.Select>
                                </div>
                                <div style={{ maxWidth: 415 }}>
                                  <h5 className="my-2">Select Value Options</h5>
                                  <MultiSelect
                                    style={{ height: 31 }}
                                    options={valueOptions}
                                    value={_selectedValueTypes}
                                    overrideStrings={{ selectSomeItems: " " }}
                                    onChange={(data) => handleValueTypesChange(data, index)}
                                    labelledBy="Select"
                                  />
                                </div>
                                {property.valueTypeIds.includes(3) &&
                                  <div style={{ maxWidth: 415 }}>
                                    <h5 className="my-2">Previous Date</h5>
                                    <Form.Control
                                      name="valueOfPreviousDate"
                                      type="date"
                                      style={{ minHeight: 40 }}
                                      value={property.valueOfPreviousDate || ""}
                                      onChange={(e) => handlePropertyDataChange(e.target.name, e.target.value, index)}
                                    />
                                  </div>
                                }
                              </Card.Body>
                              {!ticketId ? (<div className="mb-3">
                                <h5>Inspection</h5>
                                <Form.Check
                                  type="radio"
                                  name={`inspection-type-${index}`}
                                  label="Exterior"
                                  value="1"
                                  id={`interior-only-radio-${index}`}
                                  checked={inspectionType == '1'}
                                  onChange={(e) => handlePropertyDataChange('inspectionType', '1', index)}
                                  style={{ cursor: 'pointer' }}
                                />
                                <Form.Check
                                  type="radio"
                                  name={`inspection-type-${index}`}
                                  label="Interior and Exterior"
                                  id={`interior-exterior-radio-${index}`}
                                  checked={inspectionType == '2'}
                                  onChange={(e) => handlePropertyDataChange('inspectionType', '2', index)}
                                  style={{ cursor: 'pointer' }}
                                />
                                <Form.Check
                                  type="radio"
                                  name={`inspection-type-${index}`}
                                  label="None"
                                  id={`none-radio-${index}`}
                                  checked={inspectionType == '0'}
                                  onChange={(e) => handlePropertyDataChange('inspectionType', '0', index)}
                                />

                              </div>) : null}
                              <Form.Check
                                id={`isInspectionRequired-${index}`}
                                label="Inspection Required"
                                checked={property.isInspectionRequired}
                                onChange={(e) => handlePropertyDataChange("isInspectionRequired", e.target.checked, index)}
                              />
                              <div className="mb-3">
                                <h4 className="my-2">Fee ($)</h4>
                                <Form.Group className="mb-1">
                                  <Form.Control
                                    type="number"
                                    value={(property.fee || 0).toString()}
                                    onChange={(e) => feeWrapper("fee", e.target.value, index)}
                                  />
                                </Form.Group>
                              </div>
                              {/* <Card.Header className="pb-1 p-0">
                                <Card.Title tag="h5" className="mb-0">
                                  Job Related Files
                                </Card.Title>
                              </Card.Header>
                              <Card.Body className="py-2 p-0">
                                {filesList[index]?.length > 0 &&
                                  filesList[index]?.map((fileName) => (
                                    <Row>
                                      <Col
                                        xs={1}
                                        md={1}
                                        className="p-0 pt-2 d-flex justify-content-center align-items-start"
                                      >
                                        <FontAwesomeIcon icon={faImage} size="lg" />
                                      </Col>
                                      <Col xs={11} md={11}>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formNotes"
                                        >
                                          <Form.Control
                                            type="text"
                                            name="file"
                                            disabled
                                            placeholder={fileName}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  ))}

                                <Row
                                  className="mx-0 mt-4 justify-content-end upload-files"
                                >
                                  <Button
                                    variant="primary"
                                    className="mb-1 me-1 upload-files__btn"
                                  >
                                    <Form.Control
                                      type="file"
                                      name="file"
                                      onChange={(e) => onFilesChange(e, index)}
                                      className="upload-files__btn-input"
                                    />
                                    <FontAwesomeIcon icon={faFileUpload} /> Upload
                                    Files
                                  </Button>
                                </Row>
                              </Card.Body> */}
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                  </Card.Body>
                </Accordion>
                {!ticketId &&
                  (<div style={{ padding: "10px 30px" }}>
                    <div className="mb-3">
                      <h4 className="my-2">General Notes</h4>
                      <Form.Group className="mb-1" controlId="formGeneralNotes">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="formGeneralNotes"
                          value={appraisalProps.generalNotes}
                          onChange={(e) => updateAppraisalProps("generalNotes", e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>)
                }
                <div style={{ padding: "10px 30px" }}>
                  <div className="mb-3">
                    <h4 className="my-2">Addressed to</h4>
                    <Form.Group className="mb-2">
                      <Form.Check
                        id="addressed-to-same"
                        type="radio"
                        value="false"
                        label="Same as customer"
                        name="addressedToOther"
                        checked={!appraisalProps.addressedToOther}
                        onChange={(e) => updateAppraisalProps("addressedToOther", false)}
                      />
                      <Form.Check
                        id="addressed-to-other"
                        type="radio"
                        value="true"
                        label="Other"
                        name="addressedToOther"
                        checked={appraisalProps.addressedToOther}
                        onChange={(e) => updateAppraisalProps("addressedToOther", true)}
                      />
                    </Form.Group>
                    {(appraisalProps.addressedToOther) && (
                      <Row className="my-1">
                        <Col xs={12} sm={6}>
                          <Form.Group className="mb-2">
                            <Form.Control
                              type="text"
                              id={`addressedToFirstName`}
                              name={`addressedToFirstName`}
                              placeholder="First Name"
                              value={appraisalProps.addressedToFirstName || ""}
                              onChange={(e) => updateAppraisalProps("addressedToFirstName", e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Form.Group className="mb-2">
                            <Form.Control
                              type="text"
                              id={`addressedToLastName`}
                              name={`addressedToLastName`}
                              placeholder="Last Name"
                              value={appraisalProps.addressedToLastName || ""}
                              onChange={(e) => updateAppraisalProps("addressedToLastName", e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Form.Group className="mb-2">
                            <Form.Control
                              type="text"
                              id={`addressedToEmail`}
                              name={`addressedToEmail`}
                              placeholder="Email"
                              value={appraisalProps.addressedToEmail || ""}
                              onChange={(e) => updateAppraisalProps("addressedToEmail", e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Form.Group className="mb-2">
                            <Form.Control
                              type="text"
                              id={`addressedToPhone`}
                              name={`addressedToPhone`}
                              placeholder="Phone"
                              value={appraisalProps.addressedToPhone || ""}
                              onChange={(e) => updateAppraisalProps("addressedToPhone", e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                      </Row>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AppraisalDetails;
