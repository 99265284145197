import React, { useRef } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { MultiSelect } from "react-multi-select-component"
import CustomCard from '../CustomCard'
import GeneralSearch from './GeneralSearch'
import CreatePortfolioModal from './CreatePortfolioModal'
import CustomSwitch from "../../components/CustomSwitch";

/** Hooks & actions*/
import useAppSelector from '../../hooks/useAppSelector'
import useAppDispatch from '../../hooks/useAppDispatch'
import { loading, loadTicketGroups } from '../../redux/actions/ticketGroups'
import { ticketSteps, IS_PAID_ENUM } from '../../utils'

const valueRenderer = (selected) => selected.map(i => i.label).join(", ");

const JobFilters = ({ gsData, handleGSChange, handleBaseProperty, handleChildProperty }) => {
    const {
        inspectors,
        users,
        jobPurposes,
        valueOptions,
        ticketStatusTypes,
        portfolios,
        propertyTypes,
        propertyTypesMap,
        propertySubTypes,
        assignmentTypes,
    } = useAppSelector((state) => state.appData);
    const { pageIndex, pageSize } = useAppSelector(state => state.ticketGroups)
    const { filters } = useAppSelector(state => state.search)
    const dispatch = useAppDispatch()
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const stepFiltersContainer = useRef();

    const _selectedWithCompletedSteps = []

    const _selectedSteps = filters.ticketStepFilters.map((step) => {
        if (step.completed) {
            _selectedWithCompletedSteps.push({ label: `Step ${step.ticketStepTypeId}`, value: step.ticketStepTypeId });
        }
        return { label: `Step ${step.ticketStepTypeId}`, value: step.ticketStepTypeId };
    });

    const handleStepUpdated = (root, values) => {
        const _values = []
        values.forEach((value) => {
            const _stepAlreadyActivated = filters.ticketStepFilters.find((step) => step.ticketStepTypeId == value.value)
            _values.push({ ticketStepTypeId: value.value, completed: _stepAlreadyActivated?.completed ? true : false })
        })
        handleBaseProperty(root, _values)
    }

    const handleStepStatusUpdated = (root, values) => {
        const _values = []

        filters.ticketStepFilters.forEach((step) => {
            const _valueSelected = values.find((value) => value.value == step.ticketStepTypeId)
            _values.push({ ticketStepTypeId: step.ticketStepTypeId, completed: _valueSelected !== undefined })
        })
        handleBaseProperty(root, _values)
    }

    const handleKeyPressed = (e) => {
        if (e.key === "Enter") {
            dispatch(loading())
            dispatch(loadTicketGroups(pageIndex, pageSize, filters))
        }
    }

    const handleValueTypesFilterChange = (root, name, values) => {
        let _values = []
        values.forEach((value) => {
            _values.push(value.value)
        })
        handleChildProperty(root, name, _values)
    }
    
    const handleEmployeesFilterChange = (values) => {
        let _values = []
        values.forEach((value) => {
            _values.push(value.value)
        })
        handleBaseProperty("employeeIds", _values);
    }

    let _selectedValueTypes = []
    filters.propertyFilter.valueTypeIds.forEach((id) => {
        let _values = valueOptions.find((obj) => obj.value === id)
        _selectedValueTypes.push({ ..._values })
    })

    var usersOptions = users.filter(u => u.active).map(u => ({ label: `${u.firstName} ${u.lastName}`, value: u.id }));

    let _selectedEmployees = []
    filters.employeeIds?.forEach((id) => {
        let _values = usersOptions.find((obj) => obj.value === id)
        _selectedEmployees.push({ ..._values })
    })

    const selectedTicketStatuses = [];
    filters.ticketStatuses.forEach((id) => {
        const ticketStatusType = ticketStatusTypes.find((obj) => obj.id === +id);
        if (!ticketStatusType) {
            return;
        }
        selectedTicketStatuses.push({ value: ticketStatusType.id, label: ticketStatusType.name });
    });

    const multiSelectWrapperStyle = stepFiltersContainer.current?.clientWidth
        ? { width: stepFiltersContainer.current?.clientWidth }
        : {};

    return (<>
        <Row>
            <Col>
                <CustomCard title="Job">
                    <Form.Control
                        type="text"
                        name="name"
                        label="Job Name"
                        placeholder="Job Name"
                        value={filters.name}
                        autoComplete="off"
                        className="mb-2"
                        onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Select
                        name="dueDateFilterType"
                        value={filters.dueDateFilterType}
                        className="mb-2"
                        onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                    >
                        <option value="0">Select Due Date Filter Type</option>
                        <option value="1">Past due</option>
                        <option value="2">Due today</option>
                        <option value="3">Due this week</option>
                        <option value="4">Due the next [X] days</option>
                    </Form.Select>
                    {Number(filters.dueDateFilterType) === 4 &&
                        <Form.Control
                            type="text"
                            name="dueDateDays"
                            label="DueDate Days"
                            placeholder='DueDate Days'
                            autoComplete="off"
                            value={filters.dueDateDays}
                            className="mb-2"
                            onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                            onKeyPress={handleKeyPressed}
                        />
                    }
                    <Form.Control
                        type="text"
                        name="fileNumber"
                        label="File Number"
                        placeholder='File Number'
                        autoComplete="off"
                        value={filters.propertyFilter.fileNumber}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Check
                        className="mt-2"
                        type="switch"
                        id="snoozed-jobs-switch"
                        name="showSnoozedJobs"
                        label="Snoozed Jobs"
                        value={filters.showSnoozedJobs}
                        onChange={e => handleBaseProperty(e.target.name, e.target.checked)}
                    />
                </CustomCard>
                <CustomCard title="Sorting Options" className="mt-2">
                    <Form.Select
                        name="property"
                        className="mb-2"
                        value={filters.sortOptions.property}
                        onChange={(e) => handleChildProperty("sortOptions", e.target.name, e.target.value)}
                    >
                        <option value="">Select Property</option>
                        <option value="name">Name (job name)</option>
                        <option value="dueDate">DueDate</option>
                        <option value="created">Created</option>
                        <option value="lastupdatedate">Last Update Date</option>
                        <option value="priority">Priority</option>
                        {/* <option value="totalPrice">TotalPrice</option>
                        <option value="fee">Fee</option> */}
                    </Form.Select>
                    <Form.Select
                        name="type"
                        value={filters.sortOptions.type}
                        onChange={(e) => handleChildProperty("sortOptions", e.target.name, e.target.value)}
                    >
                        <option value="0">Ascending</option>
                        <option value="1">Descending</option>
                    </Form.Select>
                </CustomCard>
                <CustomCard title="Inspection Filter Type" className="mt-2">
                    <Form.Select
                        name="inspectionFilterType"
                        value={filters.inspectionFilterType}
                        className="mb-2"
                        onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                    >
                        <option value="0">Select Inspection Filter Type</option>
                        <option value="1">To Be scheduled</option>
                        <option value="6">Scheduled</option>
                        <option value="7">Scheduled For Today</option>
                        <option value="8">Inspection Complete</option>
                        <option value="5">Due the next [x] days</option>
                        {/* <option value="2">Scheduled Past due</option> */}
                        {/* <option value="3">Due today</option>
                        <option value="4">Due this week</option>
                        <option value="6">Scheduled</option> */}
                    </Form.Select>
                    {filters.inspectionFilterType === "5" && (
                        <Form.Control
                            type="number"
                            name="inspectionDateDays"
                            placeholder='Inspection Date Days'
                            autoComplete="off"
                            value={filters.inspectionDateDays}
                            className="mb-2"
                            onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                        />
                    )}
                    <Form.Select
                        name="inspectorId"
                        value={filters.inspectorId}
                        className="mb-2"
                        onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}
                    >
                        <option value="">Select Inspector</option>
                        {inspectors.map((inspector, index) => <option key={index} value={inspector.id}>{`${inspector.firstName} ${inspector.lastName}`}</option>)}
                    </Form.Select>
                    {/*<Form.Select*/}
                    {/*    name="userId"*/}
                    {/*    value={filters.userId || ""}*/}
                    {/*    onChange={(e) => handleBaseProperty(e.target.name, e.target.value)}*/}
                    {/*>*/}
                    {/*    <option value="">Select User</option>*/}
                    {/*    {users.filter(u => u.active).map((user, index) => <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>)}*/}
                    {/*</Form.Select>*/}
                </CustomCard>
                <CustomCard title="Users" className="mt-2">
                    <div className="customMultiSelect">
                        <div style={multiSelectWrapperStyle}>
                            <MultiSelect
                                options={usersOptions}
                                value={_selectedEmployees}
                                onChange={(data) => handleEmployeesFilterChange(data)}
                                labelledBy="Select"
                            />
                        </div>
                    </div>
                </CustomCard>
            </Col>
            <Col>
                <CustomCard title="Property" className="mb-2">
                    <Form.Control
                        type="text"
                        name="state"
                        label="State"
                        placeholder='State'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.propertyFilter.state}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="street"
                        label="street"
                        placeholder='Street'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.propertyFilter.street}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="zip"
                        label="zip"
                        placeholder='Zip'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.propertyFilter.zip}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="searchTerm"
                        label="Search Term"
                        placeholder='Search Term'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.propertyFilter.searchTerm}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <div style={multiSelectWrapperStyle} className="customMultiSelect">
                        <MultiSelect
                            options={propertyTypes.map((propertyType) => ({
                                value: propertyType.id,
                                label: propertyType.name,
                            }))}
                            value={(filters.propertyFilter.propertyTypeId || []).map((value) => ({
                                label: propertyTypesMap[value].name,
                                value,
                            }))}
                            onChange={(data) =>
                                handleChildProperty(
                                    "propertyFilter",
                                    "propertyTypeId",
                                    data.map((item) => item.value)
                                )
                            }
                            overrideStrings={{ selectSomeItems: "Property Type" }}
                            valueRenderer={valueRenderer}
                            className="mb-2"
                        />
                    </div>
                    {filters.propertyFilter.propertyTypeId?.length === 1 &&
                        !!propertySubTypes[filters.propertyFilter.propertyTypeId[0]]?.length && (
                            <Form.Select
                                aria-label="Property Sub Type"
                                name="propertySubTypeId"
                                className="mt-2"
                                value={filters.propertyFilter.propertySubTypeId || ""}
                                onChange={(e) =>
                                    handleChildProperty("propertyFilter", e.target.name, e.target.value)
                                }
                            >
                                <option value="">Select Property Sub Type</option>
                                {propertySubTypes[filters.propertyFilter.propertyTypeId[0]].map(
                                    (propertySubType) => (
                                        <option key={propertySubType.id} value={propertySubType.id}>
                                            {propertySubType.name}
                                        </option>
                                    )
                                )}
                            </Form.Select>
                        )}
                    <Form.Control
                        type="text"
                        name="parcelId"
                        label="Parcel ID"
                        placeholder='Parcel ID'
                        autoComplete="off"
                        className="mt-2"
                        value={filters.propertyFilter.parcelId}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Select
                        aria-label="Job Assigment Type"
                        className="mt-2"
                        name="jobAssignmentTypeId"
                        value={filters.jobAssignmentTypeId}
                        onChange={(e) => handleBaseProperty(e.target.name, +e.target.value)}
                    >
                        <option value="0">Job Assignment Type</option>
                        {assignmentTypes.map((assignmentType) => (!Number(filters.propertyFilter.type) || Number(filters.propertyFilter.type) === assignmentType.type) && (
                            <option key={assignmentType.id} value={assignmentType.id}>{assignmentType.name}</option>
                        ))}
                    </Form.Select>
                </CustomCard>
            </Col>
            <Col>
                <CustomCard title="Customer" className="mb-2">
                    <Form.Control
                        type="text"
                        name="name"
                        label="Customer Name"
                        placeholder='Customer Name'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.customerFilter.name}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="address"
                        label="Customer Address"
                        placeholder="Customer Address"
                        autoComplete="off"
                        className="mb-2"
                        value={filters.customerFilter.address}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="defaultEmail"
                        label="Customer Email"
                        placeholder='Customer Email'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.customerFilter.defaultEmail}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="defaultPhone"
                        label="Customer Phone"
                        placeholder='Customer Phone'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.customerFilter.defaultPhone}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="contactEmail"
                        label="Contact Email"
                        placeholder='Contact Email'
                        autoComplete="off"
                        className="mb-2"
                        value={filters.customerFilter.contactEmail}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                    <Form.Control
                        type="text"
                        name="contactPhone"
                        label="Contact Phone"
                        placeholder='Contact Phone'
                        autoComplete="off"
                        value={filters.customerFilter.contactPhone}
                        onChange={(e) => handleChildProperty("customerFilter", e.target.name, e.target.value)}
                        onKeyPress={handleKeyPressed}
                    />
                </CustomCard>
            </Col>
            <Col>
                <CustomCard title="General Search" className="general-search-card">
                    <GeneralSearch gsData={gsData} handleGSChange={handleGSChange} />
                </CustomCard>
                <CustomCard title="Steps" className="mt-2">
                    <div ref={stepFiltersContainer} className="customMultiSelect">
                        <div style={multiSelectWrapperStyle}>
                            <MultiSelect
                                options={ticketSteps}
                                value={_selectedSteps}
                                onChange={(data) => handleStepUpdated("ticketStepFilters", data)}
                                overrideStrings={{ selectSomeItems: "Select Step" }}
                                valueRenderer={valueRenderer}
                                className="mb-2"
                            />
                        </div>
                        <div style={multiSelectWrapperStyle}>
                            <MultiSelect
                                options={_selectedSteps}
                                value={_selectedWithCompletedSteps}
                                onChange={(data) => handleStepStatusUpdated("ticketStepFilters", data)}
                                overrideStrings={{ selectSomeItems: "Completed Steps" }}
                                valueRenderer={valueRenderer}
                            />
                        </div>
                    </div>
                </CustomCard>
                <CustomCard title="Ticket Status" className="mt-2">
                    <div className="customMultiSelect">
                        <div style={multiSelectWrapperStyle}>
                            <MultiSelect
                                options={ticketStatusTypes.map(item => ({ label: item.name, value: item.id }))}
                                value={selectedTicketStatuses}
                                onChange={(data) => handleBaseProperty("ticketStatuses", data.map(item => item.value))}
                                labelledBy="Select"
                            />
                        </div>
                    </div>
                </CustomCard>
            </Col>
            <Col>
                <CustomCard title="Total Price">
                    <Form.Control
                        type="number"
                        name="value"
                        placeholder='Total Price'
                        className="mb-2"
                        autoComplete="off"
                        value={filters.totalPrice.value}
                        onChange={(e) => handleChildProperty("totalPrice", e.target.name, e.target.value)}
                    />
                    <Form.Select name="mode" value={filters.totalPrice.mode} onChange={(e) => handleChildProperty("totalPrice", e.target.name, e.target.value)}>
                        <option value="0">Select mode (Price)</option>
                        <option value="1">Greater than the "Value"</option>
                        <option value="2">Less than the "Value"</option>
                        <option value="3">Equals To</option>
                        <option value="4">Not Equals To</option>
                    </Form.Select>
                </CustomCard>
                <CustomCard title="Job Purpose" className="mt-2">
                    <Form.Select
                        aria-label="Select Purpose"
                        name="jobPurposeId"
                        value={filters.propertyFilter.jobPurposeId}
                        onChange={(e) => handleChildProperty("propertyFilter", e.target.name, e.target.value)}
                    >
                        <option value="0">Job Purpose</option>
                        {jobPurposes.map((purpose) => <option key={purpose.id} value={purpose.id}>{purpose.name}</option>)}
                    </Form.Select>
                </CustomCard>
                <CustomCard title="Value Types" className="mt-2">
                    <div className="customMultiSelect">
                        <div style={multiSelectWrapperStyle}>
                            <MultiSelect
                                options={valueOptions}
                                value={_selectedValueTypes}
                                onChange={(data) => handleValueTypesFilterChange("propertyFilter", "valueTypeIds", data)}
                                labelledBy="Select"
                            />
                        </div>
                    </div>
                </CustomCard>
                <CustomCard title="Paid Status" className="mt-2">
                    <div>
                        <Form.Select
                            name="isPaid"
                            value={filters.isPaid ?? "All"}
                            onChange={(e) => handleBaseProperty(e.target.name, IS_PAID_ENUM[e.target.value])}
                        >
                            <option value="All">All</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </Form.Select>
                    </div>
                </CustomCard>
                <CustomCard title="Portfolio" className="mt-2">
                    <Form.Select
                        name="portfolioId"
                        className="mb-2"
                        value={filters.portfolioId || ""}
                        onChange={(e) => handleBaseProperty(e.target.name, +e.target.value)}
                    >
                        <option value="">Select Portfolio</option>
                        {portfolios.map((portfolio) => (
                            <option
                                key={`portfolio-filter-select-${portfolio.id}`}
                                value={portfolio.id}
                            >
                                {portfolio.name}
                            </option>
                        ))}
                    </Form.Select>
                    <CreatePortfolioModal />
                </CustomCard>
            </Col>
        </Row>
    </>);
}

export default JobFilters