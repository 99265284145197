export const columns = [
    {
        name: 'Name',
        sortable: false,
        cell: row => (`${(row.firstName || "")} ${(row.lastName || "")}`)
    },
    {
        name: 'Phone',
        sortable: false,
        cell: row => row.phone
    },
    {
        name: 'Email',
        sortable: false,
        cell: row => row.email
    },
    {
        name: 'Note',
        sortable: false,
        cell: row => row.note
    }
]