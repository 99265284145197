import { Link } from "react-router-dom";

export const columns = [
    {
        name: "ID",
        sortable: false,
        cell: row => row.id
    },
    {
        name: "Name",
        sortable: false,
        cell: row => <div style={{ wordBreak: "initial" }}>{row.name}</div>
    },
    {
        name: "Address",
        sortable: false,
        cell: row => row.address
    },
    {
        name: "Email",
        sortable: false,
        cell: row => <div style={{ wordBreak: "initial" }}>{row.email}</div>
    },
    {
        name: "Phone",
        sortable: false,
        cell: row => <div style={{ wordBreak: "initial" }}>{row.phone}</div>
    },
    {
        name: "Contact Details",
        sortable: false,
        cell: row => <Link to={`/dashboard/contacts/${row.id}`}>Edit Details</Link>
    }
]