import { LOAD_TICKET_GROUPS, UPDATE_API_STATUS, UPDATE_TICKET_GROUPS } from "../../actions/ticketGroups"
import { API_STATUS } from "../../../utils"

const groupsData = {
    status: API_STATUS.UNINITIALIZED,
    ticketGroups: [],
    pageIndex: 0,
    pageSize: localStorage.getItem("ticketGroupSize") || "5",
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false
}

const ticketGroupsReducer = (state = groupsData, action) => {
    switch (action.type) {
        case LOAD_TICKET_GROUPS:
            let _state = { ...state, status: API_STATUS.SUCCESS }
            Object.keys(action.payload).forEach((key) => {
                if (key === 'data')
                    _state["ticketGroups"] = action.payload[key]
                else
                    _state[key] = action.payload[key]
            })
            return _state
        case UPDATE_API_STATUS:
            return { ...state, status: action.payload }
        case UPDATE_TICKET_GROUPS:
            return { ...state, ticketGroups: action.payload }
        default:
            return state
    }
}

export default ticketGroupsReducer