import React from "react"
import { Form, Col, Button } from "react-bootstrap"
import useAppSelector from "../../../hooks/useAppSelector"

import InspectionContact from '../inspectionsContacts'
import TimePicker from '../../../components/timepicker'

import InspectorInspections from "../inspectorInspections"
import InspectionModal from "./InspectionModal"
import useJobInspectionDetails from "../useJobInspectionDetails"
import useJobDetails from "../useJobDetails"
import Files from '../../jobViewMobile/tabs/Files';


const InspectionForm = ({ inspectionForm, ticketId, saveInspectionNotes, saveInspectionFormNotes, btnLabel, handleInspectionFormChange, handleSubmit }) => {
    const { inspectors } = useAppSelector(state => state.appData)
    const { inspectorInspections } = useJobInspectionDetails();
    const { ticketInfo, handleInspectionRequiredUpdate, handleInspectionStatusNoteChange } = useJobDetails();
    const { isInspectionRequired, inspectionStatusNotes } = ticketInfo;

    return (<>
        <Col sm={12}>
            <Form.Group className="mb-2">
                <Form.Check
                    type="checkbox"
                    id="isInspectionRequired"
                    label="Inspection Required"
                    checked={isInspectionRequired === true || isInspectionRequired === null}
                    onChange={(e) => handleInspectionRequiredUpdate(e.target.checked)}
                />
            </Form.Group>
        </Col>
        <Col sm={12}>
            <h5>Inspection Memo (Internal)</h5>
            <Form.Group className="mb-2">
                <Form.Control
                    as="textarea"
                    value={inspectionStatusNotes || ""}
                    onChange={(e) => handleInspectionStatusNoteChange(e.target.value)}
                />
            </Form.Group>
        </Col>
        <Col sm={12} className="d-flex justify-content-end">
            <Button size="sm" className="px-4 rounded" onClick={() => saveInspectionNotes(inspectionStatusNotes)}>
                Save Memo
            </Button>
        </Col>
        <Col xl={6} sm={12} className="mt-1">
            <h5>Inspector</h5>
            <Form.Select value={inspectionForm.inspectorId} onChange={(e) => handleInspectionFormChange("inspectorId", e.target.value)} disabled={isInspectionRequired === false} aria-label="Select Inspector">
                <option value="">Select Inspector</option>
                {inspectors.map((inspector) => <option key={inspector.id} value={inspector.id}>{`${inspector.firstName} ${inspector.lastName}`}</option>)}
            </Form.Select>
        </Col>
        <Col xl={6} sm={12} className="mt-1">
            <h5>Inspection Type</h5>
            <Form.Select aria-label="Select Inspection Type" value={inspectionForm.type} onChange={(e) => handleInspectionFormChange("type", e.target.value)} disabled={isInspectionRequired === false}>
                <option value="">Select Inspection Type</option>
                <option value="2">Interior and Exterior</option>
                <option value="1">Exterior Only</option>
            </Form.Select>
        </Col>
        <Col xl={6} sm={12} className="mt-1">
            <h5>Inspection Date</h5>
            <div className="d-flex" style={{ width: "99%", margin: "auto" }}>
                <Form.Control
                    type="date"
                    // min={new Date().toISOString().split('T')[0]}
                    value={inspectionForm.date || ""}
                    disabled={isInspectionRequired === false}
                    onChange={(e) => handleInspectionFormChange("date", e.target.value)}
                />
            </div>
        </Col>
        <Col xl={6} sm={12} className="mt-1">
            <h5>Inspection Time</h5>
            <div className="d-flex">
                <TimePicker className="w-100" disabled={isInspectionRequired === false} value={inspectionForm.time} onChange={(time) => handleInspectionFormChange("time", time)} />
            </div>
        </Col>
        <Col xl={12} sm={12} className="mt-2">
            {inspectorInspections && inspectorInspections.length > 0 && (
                <InspectionModal disabled={isInspectionRequired === false} title="Inspector Scheduled Inspections">
                    <InspectorInspections data={inspectorInspections} />
                </InspectionModal>
            )}
        </Col>
        <Col sm={12} className="mt-2">
            <h5>Inspection Notes</h5>
            <Form.Group className="mb-2">
                <Form.Control
                    as="textarea"
                    disabled={isInspectionRequired === false}
                    value={inspectionForm.note} onChange={(e) => handleInspectionFormChange("note", e.target.value)}
                />
            </Form.Group>
        </Col>
        <Col sm={12} className="d-flex justify-content-end">
            <Button size="sm" className="px-4 rounded" onClick={() => saveInspectionFormNotes(inspectionForm.id, inspectionForm.note)}>
                Save Note
            </Button>
        </Col>
        <Col xl={12} sm={12} className="mt-1">
            <InspectionModal title="Inspection Contacts" disabled={isInspectionRequired === false} size="lg">
                <InspectionContact id={ticketId} />
            </InspectionModal>
        </Col>
        <Col xl="6" md="6" sm="12" className="mt-1">
            <Form.Group className="mb-2">
                <Form.Check
                    label="Inspection Completed"
                    type="checkbox"
                    id="inspectionCompleted"
                    checked={inspectionForm.completed}
                    disabled={isInspectionRequired === false}
                    onChange={(e) => handleInspectionFormChange("completed", e.target.checked)}
                />
            </Form.Group>
        </Col>
        <Col xl="6" md="6" sm="12" className="mt-1">
            <Form.Group className="mb-2">
                <Form.Check
                    label="Office received the photos and info"
                    type="checkbox"
                    id="photosAndInfo"
                    checked={inspectionForm.officeReceivedPhotosAndInfo}
                    disabled={isInspectionRequired === false}
                    onChange={(e) => handleInspectionFormChange("officeReceivedPhotosAndInfo", e.target.checked)}
                />
            </Form.Group>
        </Col>
        <Col xl="6" md="6" sm="12" className="mt-1">
            <Form.Group className="mb-2">
                <Form.Check
                    label="Updated Client - Inspection Scheduled"
                    type="checkbox"
                    id="inspectionScheduled"
                    checked={inspectionForm.scheduled}
                    disabled={isInspectionRequired === false}
                    onChange={(e) => handleInspectionFormChange("scheduled", e.target.checked)}
                />
            </Form.Group>
        </Col>
        <Col xl="6" md="6" sm="12" className="mt-1">
            <Form.Group className="mb-2">
                <Form.Check
                    label="Updated Client - Inspection Done"
                    type="checkbox"
                    id="inspectionDone"
                    checked={inspectionForm.done}
                    disabled={isInspectionRequired === false}
                    onChange={(e) => handleInspectionFormChange("done", e.target.checked)}
                />
            </Form.Group>
        </Col>
        <Col xl={12} className="mt-1">
            <Button style={{ width: "inherit" }} onClick={handleSubmit} disabled={inspectionForm.inspectorId === "" || inspectionForm.type === "" || inspectionForm.date === null || inspectionForm.time === null || isInspectionRequired === false} >
                {btnLabel}
            </Button>
        </Col>
        <Col xl={12} className="mt-1">
            <div className="my-1 inspection-content-button-wrapper">
                {/* <Files */}
                <Files uploadEnabled hideCamera />
                {/*                     
                    <ShowImages /> */}
            </div>
        </Col>
    </>)
}

export default InspectionForm