import React, { useEffect } from "react"

//** Components */
import Customer from "./customer"
import PropertyLists from "./propertyList"
import Paragraphs from "./paragraphs"

//** Default Data & Configs*/
import { proposalParagraphs } from "./defaultData"
import { SERVER_URL } from "../../configs"

//** Supporting Libs */
import axios from "axios"
import moment from "moment"

/** HOOKS */
import useAppSelector from "../../hooks/useAppSelector"

const DocumentGeneration = ({ ticketGroup, documentData, setDocumentData }) => {
    const { propertyTypes, jobPurposes } = useAppSelector(state => state.appData)
    const GetPropertyType = (id) => {
        let _type = propertyTypes.find((propertyType) => propertyType.id === id)
        return _type ? _type.type : ""
    }
    //Customer Object
    useEffect(() => {
        if (ticketGroup?.tickets?.length > 0) {
            let _customerId = ticketGroup.tickets[0].property.customerId
            if (_customerId)
                axios.get(`${SERVER_URL}/api/Customer/GetCustomerWithContacts?customerId=${_customerId}`).then((res) => {
                    let contactName = res.data?.customerContacts[0]?.firstName ? res.data?.customerContacts[0]?.firstName : ""
                    contactName = res.data?.customerContacts[0]?.lastName ? (`${contactName} ` + res.data?.customerContacts[0]?.lastName) : contactName

                    let _obj = {
                        id: res.data.id,
                        fullName: res.data.name,
                        address: res.data.address,
                        email: res.data?.customerContacts[0]?.customerContactEmails[0]?.email,
                        phone: res.data?.customerContacts[0]?.customerContactPhoneNumbers[0]?.phone,
                        contactName
                    }
                    setDocumentData(prevState => ({ ...prevState, customer: _obj }))
                })
        }
    }, [ticketGroup])

    //Addresses and paragraphs
    useEffect(() => {
        let _propertyList = []
        ticketGroup?.tickets?.forEach((ticket) => {
            let _obj = {}
            _obj["valuationDate"] = ticket.valueOfPreviousDate ? ticket.valueOfPreviousDate : null;
            if (_obj["valuationDate"]) {
                _obj["valuationDate"] = moment(_obj["valuationDate"]).format('YYYY-MM-DD');
            } else {
                _obj["valuationDate"] = moment(new Date()).format('YYYY-MM-DD');
            }
            _obj["propertyType"] = ticket.property.propertyTypeId
            _obj["assignmentType"] = ticket.jobAssignmentTypeId
            _obj["address"] = ticket.property.address
            _obj["pType"] = GetPropertyType(ticket.property.propertyTypeId)
            _obj["valueType"] = ticket.valueTypes
            _obj["parcelId"] = ticket.property.parcelId
            _obj["id"] = ticket.id
            _obj["unit"] = ticket.property.unit;
            _obj["valueOfPreviousDate"] = ticket.valueOfPreviousDate;

            _propertyList.push(_obj)
        })

        let _proposalParagraphs = JSON.parse(JSON.stringify(proposalParagraphs));
        let jobPurposeId = ticketGroup?.tickets[0]?.jobPurposeId
        let job = jobPurposes.find((purpose) => purpose.id === jobPurposeId)
        let _jobPurposeStr = "Gift and Estate Tax Planning purposes."
        if (job)
            _jobPurposeStr = `${job.name} purposes.`

        for (let paragraph of _proposalParagraphs) {
            if (paragraph.id === 1) {
                paragraph.text += _jobPurposeStr
                break
            }
        }
        setDocumentData(prevState => ({ ...prevState, propertyList: _propertyList, proposalParagraphs: _proposalParagraphs }))
        loadQuotes(_propertyList)
    }, [ticketGroup])

    const loadQuotes = (_propertyList) => {
        _propertyList = JSON.parse(JSON.stringify(_propertyList))
        let promises = []
        _propertyList.forEach((property) => {
            promises.push(axios.get(`${SERVER_URL}/api/TicketQuote/GetQuotesByTicketId?ticketId=${property.id}`).then((res) => {
                property["quotes"] = res.data
            }).catch((err) => {
                property["quotes"] = []
            }))
        })
        Promise.all(promises).then(() => {
            setDocumentData(prevState => ({ ...prevState, propertyList: _propertyList }))
        })

    }
    const updateData = (key, value) => {
        setDocumentData({ ...documentData, [key]: value })
    }

    return (
        <div>
            {
                documentData["customer"] && <Customer customer={documentData["customer"]} setCustomer={(data) => updateData("customer", data)} />
            }
            {
                documentData["propertyList"] && <PropertyLists propertyList={documentData["propertyList"]} setPropertyList={(data) => updateData("propertyList", data)} />
            }
            {
                documentData["proposalParagraphs"] && <Paragraphs paragraphs={documentData["proposalParagraphs"]} setParagraphs={(data) => updateData("proposalParagraphs", data)} />
            }
        </div>)
}

export default DocumentGeneration