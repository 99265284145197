import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Tooltip } from "react-bootstrap";
import { MoreVertical } from "react-feather";
import StepBadge from "./StepBadge";
// import TicketGroupControl from "./TicketGroupControl";
import { getDateString } from "../../utils";
import { getAssignedUser, getDateTime } from "./helpers";
import useAppSelector from "../../hooks/useAppSelector";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Link, Navigate } from "react-router-dom";
import { getTicketsWithAddress } from "../../services/ticket";
import { ReactComponent as SnoozeIcon } from "../../assets/icons/snooze.svg";
import { ReactComponent as SnoozeAppliedIcon } from "../../assets/icons/snooze_applied.svg";
import { ENABLE_SNOOZE } from "../../configs";
import { TICKET_STATUSES } from "../../constants";

const statusMap = {
  Completed: "primary",
  Active: "secondary",
  Pending: "light",
  "In Progress": "secondary",
};

const dotStyle = {
  marginLeft: '3px',
  height: '14px',
  width: '15px',
  backgroundColor: 'red',
  borderRadius: '50%',
  display: 'inline-block',
  textAlign: 'center',
  color: 'white',
  fontSize: '9px'
  //marginBottom: '5px'
}

const StatusButton = ({ status, onClick, backgroundColor }) => (
  <Button
    variant={statusMap[status]}
    className="rounded-pill"
    size="sm"
    onClick={onClick}
  // style={{ backgroundColor }}
  >
    {status}
  </Button>
);

const TicketListItem = ({
  ticket,
  ticketGroup,
  openDueDateChangeModal,
  setShowTicketStatusModal,
  setStatusModalTicket,
  setCancelJobId,
  handleRowClicked,
  onRemoveFromPortfolio,
  onAddToPortfolio,
  ticketStatusTypeName,
  ticketStatusTypeId,
  ticketStatusTypeColor,
  openSnoozeModal
}) => {

  const propertyTypes = useAppSelector((state) => state.appData.propertyTypes);
  const [ticketsWithSameAddress, setTicketsWithSameAddress] = useState([]);
  const usersMap = useAppSelector((state) => state.appData.usersMap);

  const appliedFilters = useAppSelector((state) => state.search.appliedFilters);
  const appliedFiltersValue = useAppSelector((state) => state.search.filters);
  const [toBeScheduledFilterApplied, setToBeScheduledFilterApplied] = useState(false);

  useEffect(() => {
    setToBeScheduledFilterApplied(appliedFilters.includes('inspectionFilterType') && appliedFiltersValue.inspectionFilterType == "1");
  }, [appliedFilters, appliedFiltersValue.inspectionFilterType]);

  const propertyType = useMemo(() => {
    if (!propertyTypes.length || !ticket.property?.propertyTypeId) {
      return;
    }
    return propertyTypes.find(
      (item) => item.id === ticket.property.propertyTypeId
    );
  }, [propertyTypes, ticket.property?.propertyTypeId]);

  const onClick = () => {
    handleRowClicked(ticketGroup.id, ticket.id);
  };

  useEffect(() => {
    if (!ticket.property?.similarPropertiesCount) {
      return;
    }

    getTicketsWithAddress(ticket.property.googlePlaceId, ticket.property.unit).then(response => {
        response.forEach(g => {
            g.tickets = g.tickets.filter(t => t.id !== ticket.id);
        })
        setTicketsWithSameAddress(response.filter(t => t.tickets.length > 0));
    });

  }, [ticket.property?.similarPropertiesCount, ticket.property.googlePlaceId]);

  const snoozeHandler = () => {
    openSnoozeModal(ticket);
  }

  const getSnoozeIconProps = () => ({
    style: { cursor: 'pointer' },
    onClick: snoozeHandler,
    height: 16,
    width: 16
  });

  return (
    <tr
      data-id={ticket.id}
      data-priority={ticket.priorityIndex}
      data-ticketgroup={ticketGroup.id}
      className="job-row"
    >
      <td style={{ cursor: "default" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignSelf: 'flex-start' }}>
          <StatusButton
            style={{ width: '80%' }}
            onClick={() => {
              setShowTicketStatusModal(true);
              setStatusModalTicket(ticket);
            }}
            status={ticketStatusTypeName || "In Progress"}
            backgroundColor={ticketStatusTypeColor}
          />
          {ENABLE_SNOOZE &&
            (ticketStatusTypeId == TICKET_STATUSES.ON_HOLD || ticketStatusTypeId == TICKET_STATUSES.PENDING || toBeScheduledFilterApplied) ?
            (<div className="ms-2" style={{ width: '20%', display: 'flex' }}>
              {ticket.snoozeUntil != null ?
                (
                  <OverlayTrigger
                    delay={{ hide: 500, show: 300 }}
                    placement="bottom"
                    overlay={(props) => (
                      <Tooltip {...props}>
                        <p style={{ fontWeight: 'bold' }} className="mt-1">Snoozed until - {getDateString(ticket.snoozeUntil.split('T')[0])}</p>
                        <p style={{ fontWeight: 'bold' }}>Note - {(new String(ticket.snoozeReason)).substring(0, 20)}...</p>
                        {ticket.snoozedByUserId ? (<p style={{ fontWeight: 'bold' }}>By - {usersMap[ticket.snoozedByUserId].firstName} {usersMap[ticket.snoozedByUserId].lastName}</p>) : null}
                      </Tooltip>
                    )}>
                    <SnoozeAppliedIcon {...getSnoozeIconProps()} />
                  </OverlayTrigger>

                ) :
                <SnoozeIcon {...getSnoozeIconProps()} />}
            </div>) : null
          }
        </div>
      </td>
      <td onClick={onClick}>
        {ticket.property?.neighborhood ||
          `${ticket.property?.city} ${ticket.property?.state}`}

      </td>
      <td>
        <>
          <span onClick={onClick}>{`${ticket.property?.houseNumber || ""} ${ticket.property?.street || ""
            }`.trim() || ticketGroup.name}
          </span>
              </>
              {ticket.property?.similarPropertiesCount && ticketsWithSameAddress && ticketsWithSameAddress.length > 0 &&
          <OverlayTrigger
            delay={{ hide: 3000, show: 300 }}
            placement="bottom"
            overlay={(props) => (
              <Tooltip {...props}>
                {ticketsWithSameAddress && ticketsWithSameAddress?.map(t =>
                (<div key={t.id} style={{ width: '190px' }} className="d-flex justify-content-around">
                    <p>{t.name} {t.tickets[0]?.property?.unit}</p>
                  <Link to={`/dashboard/job/${t.id}/${t.tickets[0].id}`}>Go &rarr;</Link>
                </div>))}
              </Tooltip>
            )}>
            <span title={`${ticket.property?.similarPropertiesCount} ${ticket.property?.similarPropertiesCount == 1 ? "property has" : "properties have"} the same address`} style={dotStyle}>{ticket.property?.similarPropertiesCount}</span>
          </OverlayTrigger>
        }
      </td>
      <td onClick={onClick}>{propertyType?.name || "-"}</td>
      <td onClick={onClick}>{ticket.property?.unit || "-"}</td>
      <td onClick={onClick}>
        {ticket.inspections.some(x => !x.inspectorId) ? '-' : (getDateString(ticket.inspections[0]?.date) || "-")}
      </td>
      <td onClick={onClick}>
        {usersMap[ticket.inspections[0]?.inspectorId]?.firstName || "-"}
      </td>
      <td onClick={onClick} className="text-nowrap">
        <div className="d-flex">
          {(ticket.ticketSteps?.length > 0
            ? ticket.ticketSteps
            : Array(5).fill("")
          ).map((step, index) => (
            <StepBadge
              key={index}
              step={step}
              index={index}
              assignedUserName={usersMap[step.assignedToId]?.firstName}
            />
          ))}
        </div>
      </td>
      <td onClick={onClick}>{getAssignedUser(ticket, usersMap)}</td>
      <td onClick={onClick}>{ticket.fileNumber || "-"}</td>
      <td onClick={onClick}>
        {getDateTime(ticket.dueDateOverride || ticketGroup.dueDate)}
      </td>
      <td style={{ cursor: "default" }}>
        <Dropdown>
          <Dropdown.Toggle>
            <MoreVertical size={20} style={{ cursor: "pointer" }} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#" onClick={() => setCancelJobId(ticket.id)}>
              Cancel job
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() => openDueDateChangeModal(ticket, ticketGroup)}
            >
              Change due date
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() =>
                !ticket.portfolioId
                  ? onAddToPortfolio(ticket.id)
                  : onRemoveFromPortfolio(ticket.id, ticket.portfolioId)
              }
            >
              {!ticket.portfolioId
                ? "Add to portfolio"
                : "Remove from portfolio"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td onClick={onClick}>
        {ticketGroup?.customerContact?.customer?.name || ""}
      </td>
      <td onClick={onClick}>{ticket.priorityIndex}</td>
      {/* <td style={{ cursor: "default" }} className="text-end">
        {ticketIndex === 0 && !!ticketGroup.ticketGroupStatuses?.length && (
          <TicketGroupControl ticketGroup={ticketGroup} />
        )}
      </td> */}
    </tr>
  );
};

export default TicketListItem;
