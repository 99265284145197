import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "sortOptions@property": "Sort by",
      "sortOptions@type": "Sort option type",
      "name": "Name",
      "dueDateFilterType": "Due Date Filter Type",
      "dueDateDays": "Due Date days",
      "userId": "User",
      "inspectionFilterType": "Inspection Filter Type",
      "inspectionDateDays": "Inspection Date Days",
      "inspectorId": "Inspector ID",
      "ticketStatuses": "Ticket Statuses",
      "isPaid": "Is Paid",
      "portfolioId": "Portfolio",
      "ticketStepFilters": "Ticket Step Filters",
      "jobAssignmentTypeId": "Job Assignment Type",
      "propertyFilter@city": "Property city",
      "propertyFilter@state": "Property state",
      "propertyFilter@county": "Property county",
      "propertyFilter@neighborhood": "Property neighborhood",
      "propertyFilter@searchTerm": "Search Term",
      "propertyFilter@parcelId": "Property parcel id",
      "propertyFilter@fileNumber": "Property file number",
      "propertyFilter@street": "Property street",
      "propertyFilter@zip": "Property zip",
      "propertyFilter@type": "Property Category",
      "propertyFilter@valueTypeIds": "Property Value Types",
      "propertyFilter@jobPurposeId": "Job Purpose",
      "propertyFilter@propertyTypeId": "Property Type",
      "propertyFilter@propertySubTypeId": "Property Sub Type",

      "customerFilter@name": "Customer Name",
      "customerFilter@address": "Customer Address",
      "customerFilter@defaultEmail": "Customer Default Email",
      "customerFilter@defaultPhone": "Customer Default Phone",
      "customerFilter@firstName": "Customer First Name",
      "customerFilter@lastName": "Customer Last Name",
      "customerFilter@contactEmail": "Customer Contact Email",
      "customerFilter@contactPhone": "Customer Contact Phone",
      "totalPrice@value": "Total Price Value",
      "totalPrice@mode": "Total Price Mode",
      "includeInspections": "Include inspections",
      "showSnoozedJobs": "Show Snoozed Jobs",
      "employeeIds": "Users"
    },
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
