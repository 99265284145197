import React, { useEffect, useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import useAppSelector from "../../../hooks/useAppSelector";
import { GetTicketStepTypeUserMappings, MapOrUpdateUserToTicketStepType } from "../../../services/ticketStepTypeUserMapping";
import { GetAppSettings, UpdateInspectionDoneNotificationUserId } from "../../../services/appSettings";

const StepsPage = () => {

  const [loading, setLoading] = useState(false);
  const ticketStepTypes = useAppSelector(state => state.appData.ticketStepTypes)
  const users = useAppSelector(state => state.appData.users)

  const [mappings, setMappings] = useState([]);
  const [inspectionDoneNotificationUserId, setInspectionDoneNotificationUserId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [data, settings] = await Promise.all([
          GetTicketStepTypeUserMappings(),
          GetAppSettings(),
        ]);

        // Add missing mappings
        ticketStepTypes.forEach(s => {
          for (var i = 1; i <= 3; i++) {
            let propertyType = i;
            let mapping = data.find(m => m.ticketStepTypeId === s.id && m.propertyType === propertyType);
            if (!mapping) {
              mapping = {
                ticketStepTypeId: s.id,
                propertyType: Number(propertyType),
                userId: null
              };
              data.push(mapping);
            }
          }
        });

        setMappings(data);
        setInspectionDoneNotificationUserId(settings.inspectionDoneNotificationUserId);
        setLoading(false);

      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  const UserSelect = ({ mapping, onChange }) => {
    return (
      <Form.Select value={mapping.userId || ''} onChange={(e) => onChange(e, mapping)}>
        <option value=''>NA</option>
        {users.filter(u => u.active).map((user) => (
          <option key={user.id} value={user.id}>
            {user.firstName} {user.latsName}
          </option>
        ))}
      </Form.Select>
    );
  };

  const handleSelectChange = (e, mapping) => {

    let newUserId = e.target.value;

    setMappings((prevMappings) =>
      prevMappings.map((m) =>
        m.ticketStepTypeId === mapping.ticketStepTypeId && m.propertyType === mapping.propertyType
          ? { ...m, userId: newUserId }
          : m
      )
    );

    setLoading(true);
    MapOrUpdateUserToTicketStepType(newUserId, mapping.ticketStepTypeId, mapping.propertyType).then((data) => {
      setLoading(false);
    });

  };

  const handleInspectionDoneNotificationUserIdChange = (e) => {

    let newUserId = e.target.value;

    setInspectionDoneNotificationUserId(newUserId);

    setLoading(true);
    UpdateInspectionDoneNotificationUserId(newUserId).then((data) => {
      setLoading(false);
    });

  };

  const TableRow = ({ name, propertyType, ticketStepTypes, mappings, handleSelectChange }) => {
    return (
      <tr>
        <td>{name}</td>
        {ticketStepTypes.map((s) => {
          let mapping = mappings.find(
            (m) => m.ticketStepTypeId === s.id && m.propertyType === Number(propertyType)
          );
          if (!mapping) {
            return <td key={s.id}></td>;
          }

          return (
            <td key={s.id}>
              <UserSelect
                mapping={mapping}
                onChange={handleSelectChange}
              />
            </td>
          );
        })}
      </tr>
    );
  }

  const TableBody = ({ ticketStepTypes, mappings, handleSelectChange }) => {
    return (
      <tbody>

        <TableRow
          ticketStepTypes={ticketStepTypes}
          mappings={mappings}
          handleSelectChange={handleSelectChange}
          name='Commercial' propertyType='1'>
        </TableRow>
        <TableRow
          ticketStepTypes={ticketStepTypes}
          mappings={mappings}
          handleSelectChange={handleSelectChange}
          name='Residential' propertyType='2'>
        </TableRow>
        <TableRow
          ticketStepTypes={ticketStepTypes}
          mappings={mappings}
          handleSelectChange={handleSelectChange}
          name='Entity' propertyType='3'>
        </TableRow>

      </tbody>
    );
  };

  return (
    <Container className="h-100">
      <Helmet title='Steps' />
      <div className="d-flex justify-content-between mb-3">
        <h2>Assignments Config</h2>
      </div>
      <LoadingWrapper loading={loading}>
        <Table striped>
          <thead>
            <tr>
              <th>Property Type</th>
              {ticketStepTypes.map(s =>
                <th key={s.id}>{s.name}</th>
              )}
            </tr>
          </thead>
          <TableBody
            ticketStepTypes={ticketStepTypes}
            mappings={mappings}
            handleSelectChange={handleSelectChange}>
          </TableBody>
        </Table>
        <hr />
        <h4>When inspection is done,</h4>
        <div className="d-flex align-items-center gap-1 mb-3">
          <div>
            assign a task to:
          </div>
          <div>
            <Form.Select
              style={{ minWidth: 200 }}
              value={inspectionDoneNotificationUserId || ''}
              onChange={handleInspectionDoneNotificationUserIdChange}>
              <option value=''>NA</option>
              {users.filter(u => u.active).map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.latsName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </LoadingWrapper>
    </Container>
  );
};

export default StepsPage;
