let serverUrl = "https://republicvaluation.azurewebsites.net";
if (window.location.hostname === "stage-valuflow.webarysites.com") {
    console.log("This is the stage URL.");
    serverUrl = "https://republic.webarysites.com";
} else {
    console.log("This is not the stage URL.");
}

export const SERVER_URL = serverUrl

//export const SERVER_URL = "https://localhost:44354"
export const GRAPH_API_URL = "https://graph.microsoft.com/v1.0/";

export const ENABLE_SNOOZE = true;