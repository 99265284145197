import React, { memo, useEffect, useMemo, useState } from "react";
import { getInspectionContactsByTicket } from "../../../services/inspectionContact";
import { useParams } from "react-router-dom";
import useJobDetails from "../../jobDetails/useJobDetails";
import useJobInspectionDetails from "../../jobDetails/useJobInspectionDetails";
import { INSPECTION_TYPES } from "../../../constants";
import classNames from "classnames";
import "./../jobView.css";

import { ReactComponent as InfoIcon } from "./../../../assets/icons/info.svg";
import { ReactComponent as NotesIcon } from "./../../../assets/icons/notes.svg";
import { ReactComponent as PersonIcon } from "./../../../assets/icons/person.svg";
import { ReactComponent as PhoneIcon } from "./../../../assets/icons/phone.svg";
import { Form } from "react-bootstrap";
import Switch from "../Switch";

import useAppSelector from "../../../hooks/useAppSelector";

const Details = ({ checked, handleCompletedChange }) => {
  const { ticketId } = useParams();

  const [inspectionContacts, setInspectionContacts] = useState([]);
  const {
    ticketInfo,
    assignmentTypeStr,
    propertyTypeStr,
    propertySubTypeStr,
    jobPurposeStr,
  } = useJobDetails();
  const { scheduledInspection, handleInspectionFormChange, updateInspection } = useJobInspectionDetails();

  const usersMap = useAppSelector(state => state.appData.usersMap);

  useEffect(() => {
    getInspectionContactsByTicket(ticketId).then(setInspectionContacts);
  }, [ticketId]);

  const inspectionType = useMemo(
    () =>
      INSPECTION_TYPES.find((type) => type.id === scheduledInspection.type)
        ?.type,
    [scheduledInspection.type]
  );

  const signingAppraiser = useMemo(() => {
    if (!ticketInfo.signingAppraiserId) {
      return '';
    }

    const user = usersMap[ticketInfo.signingAppraiserId];
    if (!user) {
      return '';
    }

    return `${user.firstName} ${user.lastName}`;
  }, [usersMap, ticketInfo.signingAppraiserId]);

  const handleOfficeReceivedInfoUpdate = (e) => {
    handleInspectionFormChange(e.target.name, e.target.checked, updateInspection);
  }

  return (
    <>
      <div className="border rounded-lg p-2">
        <div className="d-flex align-items-center mb-2">
          <InfoIcon width={18} className="me-2" />
          <span className="fs-4 font-weight-bold">Job Details</span>
        </div>
        <ul className="job-details-list">
          <li>
            <span>Inspection type</span>
            <span>{inspectionType}</span>
          </li>
          <li>
            <span>Type</span>
            <span>{propertyTypeStr}</span>
          </li>
          <li>
            <span>Sub Type</span>
            <span>{propertySubTypeStr}</span>
          </li>
          <li>
            <span>Job Purpose</span>
            <span>{jobPurposeStr}</span>
          </li>
          <li>
            <span>Assignment Type</span>
            <span>{assignmentTypeStr}</span>
          </li>
          <li>
            <span>Value Types</span>
            <span>
              {(ticketInfo.valueTypes?.map((i) => i.name) || []).join(", ")}
            </span>
          </li>
          <li>
            <span>Signing Appraiser</span>
            <span>
              {signingAppraiser}
            </span>
          </li>
          {/* <li>
            <span>Office received the photos and info</span>
            <span>
              {scheduledInspection.officeReceivedPhotosAndInfo ? "Yes" : "No"}
            </span>
          </li> */}
        </ul>
      </div>

      {ticketInfo.inspectionStatusNotes && (
        <div className="border rounded-lg p-2 mt-2">
          <div className="d-flex align-items-center mb-2">
            <NotesIcon width={20} className="me-2" />
            <span className="fs-4 font-weight-bold">Inspection Notes</span>
          </div>
          <p className="notes-text mb-1">{scheduledInspection.note}</p>
        </div>
      )}

      <div className="border rounded-lg p-2 mt-2">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h5 className="me-2">
            <Form.Check id="cb" name="officeReceivedPhotosAndInfo" type="checkbox" label="Office received the photos and info" onChange={handleOfficeReceivedInfoUpdate} checked={scheduledInspection.officeReceivedPhotosAndInfo} />
          </h5>

          <Switch isOn={checked} handleToggle={handleCompletedChange} />
        </div>
      </div>

      <div className="mt-3">
        {inspectionContacts.filter(x => x.firstName != '' || x.lastName != '' || x.email != '' || x.phone != '').length != 0 &&
          (<div className="d-flex align-items-center mb-2">
            <PersonIcon width={15} className="me-2" />
            <span className="fs-4 font-weight-bold">Contacts</span>
          </div>)}
        {inspectionContacts.filter(x => x.firstName != '' || x.lastName != '' || x.email != '' || x.phone != '').map((item, index) => (
          <div
            key={index}
            className={classNames("contacts-wrapper p-2", {
              "rounded-top-lg": index === 0,
              "rounded-bottom-lg": index === inspectionContacts.length - 1,
            })}
          >
            <div className="p-2 bg-white rounded-lg">
              {
                item.phone && (<div className="d-flex justify-content-between align-items-start">
                  <div>
                    <div className="contact-name font-weight-bold">
                      {item.firstName || ""} {item.lastName || ""}
                    </div>
                    <div className="contact-email">{item.email}</div>
                  </div>
                  <PhoneNumber phone={item.phone} />
                </div>)
              }
              {item.note && (
                <div className="d-flex align-items-center contact-note mt-2">
                  <InfoIcon width={12} className="me-1" />
                  <span className="fs-6 font-weight-bold text-black">
                    {item.note}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const PhoneNumber = memo(({ phone }) => {
  return (
    <div className="d-flex align-items-center rounded bg-secondary px-2 py-1">
      <PhoneIcon className="me-1" />
      <a
        href={`tel:${phone}`}
        className="text-white font-weight-bold phone-number"
      >
        {phone}
      </a>
    </div>
  )
});

export default Details;
