import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import CustomCard from "../../../components/CustomCard";
import { getCustomerRules } from "../../../services/customer";
import useAppSelector from "../../../hooks/useAppSelector";

import CompanyDetailsIcon from "../icons/CompanyDetails";
import ContactDetailsIcon from "../icons/ContactDetails";
import { updateTicketGroupContact } from "../../../services/ticketGroup";
import { toast } from "react-toastify";

const Customer = ({ customerInfo, ticketGroup, customerContactId }) => {
  const { customerRules: mainCustomerRules } = useAppSelector(state => state.appData);
  const [customerRules, setCustomerRules] = useState([]);
  const [contactId, setContactId] = useState();

  useEffect(() => {
    setContactId(customerContactId);
  }, []);

  useEffect(() => {
    if (!customerInfo.id) {
      return;
    }
    getCustomerRules(customerInfo.id).then(setCustomerRules);
  }, [customerInfo.id]);

  const handleUpdateTicketGroupContact = async (id) => {
    try {
      await updateTicketGroupContact(ticketGroup.id, id);
      setContactId(id);
      toast.success("Contact updated successfully !!");
    } catch (err) {
      console.error(err);
      toast.error(
        "Failed to update contact, please contact administrator !!!"
      );
    }
  }

  return (
    <>
      {ticketGroup.addressedToOther && (
        <div className="mb-2">
          <CustomCard
            title={
              <>
                <ContactDetailsIcon />
                Addressed to
              </>
            }
          >
            {(ticketGroup.addressedToFirstName || ticketGroup.addressedToLastName) && <p>{ticketGroup.addressedToFirstName} {ticketGroup.addressedToLastName}</p>}
            {ticketGroup.addressedToEmail && <p>{ticketGroup.addressedToEmail}</p>}
            {ticketGroup.addressedToPhone && <p>{ticketGroup.addressedToPhone}</p>}
          </CustomCard>
        </div>
      )}
      <CustomCard
        title={
          <>
            <CompanyDetailsIcon />
            Company Details
          </>
        }
      >
        {customerInfo.name && <p>{customerInfo.name}</p>}
        {customerInfo.address && <p>{customerInfo.address}</p>}
        {customerInfo.email && <p>{customerInfo.email}</p>}
        {customerInfo.phone && <p>{customerInfo.phone}</p>}
      </CustomCard>
      <CustomCard
        title={
          <>
            <ContactDetailsIcon />
            People Details
          </>
        }
      >
        {customerInfo?.customerContacts?.sort((a, b) => {
          // Check if either a or b has the primaryContactId
          const aIsPrimary = a.id === contactId;
          const bIsPrimary = b.id === contactId;

          if (aIsPrimary && !bIsPrimary) {
            return -1; // a comes first
          } else if (!aIsPrimary && bIsPrimary) {
            return 1; // b comes first
          } else {
            return 0; // maintain the current order
          }
        }).map((contact, index) => (
          <div className="mb-2">
            <CustomCard
            key={index}
            bg="light"
            title={`${contact.firstName || ""} ${contact.lastName || ""}`}
            className="mb-0"
            >
            {
              contactId !== contact.id ? (
                <div>
                  <Button
                    onClick={(e) => handleUpdateTicketGroupContact(contact.id)}
                    style={{ float: 'right' }}
                  >
                    Set Contact
                  </Button>
                </div>
              )
              :
              (
                <div style={{ float: 'right' }}>Contact</div>
              )
            }
            {contact.customerContactEmails.map((item, index) => (
                <p key={index} className="mb-1">
                {item.email}
                </p>
            ))}
            {contact.customerContactPhoneNumbers.map((item, index) => (
                <p key={index} className="mb-1">
                {item.phone}
                </p>
            ))}
            
            </CustomCard>
          </div>
        ))}
      </CustomCard>
      {customerRules.length && (
        <CustomCard title="Customer Rules">
          {mainCustomerRules.map((customerRule) => (
            <Form.Group key={customerRule.id} className="mb-2">
              <Form.Check
                label={customerRule.name}
                checked={
                  !!customerRules.find(
                    (item) => customerRule.id === item.id
                  )
                }
                disabled
              />
            </Form.Group>
          ))}
        </CustomCard>
      )}
    </>
  );
};

export default Customer;
